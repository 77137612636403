import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Scorpio.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Scorpio Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/scorpio"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Scorpio</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Scorpio Woman</h4>
        
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Scorpio Woman </h1>
                <h2 className="text-sm md:text-base ml-4">Oct 24 - Nov 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-love");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-nature");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-man");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-traits");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-man");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-traits");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-facts");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            The Scorpio women are also driven, ambitious and formidable. Their traditional way of life is based on the need to be as strong as possible. Through physical strength, they often take on roles that others would condemn. 
<br/><br/>
They are also susceptible and prone to emotional outbursts. The Janampatri is a form of work that is made by women who are born under this Sign. It will be a powerful experience to examine yourself in this category.
<br/><br/>
The women born under this Sign have a fantastic persona and excellent energy levels. We recommend you buy a personalised Janampatri report to learn about the various aspects of your life and future.
 <br/><br/>
You might find it challenging to get to know Scorpio women, but you will be amazed at how much you learn about them. 
They are usually quiet and reserved, but it is in a particular way when they open up. Instead, they won't share much, preferring to communicate through subtle hints and observations.
<br/><br/>
They have an air of mystery and fascination that makes them quite the attraction. They are passionate and determined and will stop at nothing until they get what they want. They don't like to be touched and will draw back and close themselves off if someone tries to get too close.
 <br/><br/>
Not many people know about their inner world. Still, once you get to know them well, you will realize that they are pretty delicate. They are also very focused on their goals and understand the need to set boundaries. They are straight to the point and don't like to sugarcoat anything.
<br/><br/>
The Scorpio women are intensely private and only a selected few will get near her. Distinct and tight-lipped, these women have a mysterious aura around them, which gives vibrations that they are not what they seem. The Scorpio women are intensely private and only a selected few will get near her.
 <br/><br/>
 
 
 
The Scorpio women live in reality and constantly look for clarity, as ambiguity makes them uneasy. They want to know everything, and they want to remain in control of the situation all the time. 
 <br/><br/>
Moreover, you may get Your Future in 2024 – Month-by-Month predictions report to know about your destiny in 2024.
 <br/><br/>
We are discussing the case of a woman who was selected to be a member of a social group. After exhausting all of her potential options for joining the group, we decided to sign up for that group herself. 
<br/><br/>
After spending a substantial amount of time and money on the group and developing a social network of friends who were members, she was assigned to be the director of the group. After a month, she found out that her fellow directors had taken over the position and had been appointed.
<br/><br/>
There is nothing you can do to stop their efforts; the pursuit of power will always find a way to ensure that you maintain your status. Still, you can do your best to help them realize their goals, even if it means breaking the game's laws.
I argue that his power and position in society are central to her success in a particular situation.
 <br/><br/>
Scorpio women are known to be independent, and they don't constantly need someone to be with them to lift their spirits. A project of one's own is not for everyone. It takes a certain amount of energy to work on something of your own. But, at the same time, a project of one's own is the only way that you can be fully involved in what you are doing. It is more than enough to motivate some people to work.
 <br/><br/>
They can be very hard on themselves, and when they don't work hard enough, they can get furious.
 
 <br/><br/>
One of the most common causes of interpersonal conflicts is terrible behavior from one or both parties. The most challenging part about dealing with a difficult person is that it makes you feel like you're the difficult one. But the truth is, you have to deal with difficult people in life, and you need to make the best of it. You can't let the behavior of a few people determine how you act toward your fellow human beings.<br/><br/>
The real reason people are insufficient to you is not because of how you made them feel but because of what they think of themselves. If you are harmful to people, they will remember that and try to get even. Besides, the Remedial Solutions for Personal Issues report will show you how to treat people with respect and kindness. That is how you will make people like you.
 <br/><br/>
 
The Scorpio women have superb reasoning power, which facilitates them to make the right decision at a reasonable time. Although they might look slow, deliberate, and even a little absent-minded, you should never doubt the speed and decisiveness of your decision when you're in the hands of a meticulous man. 
 <br/><br/>
He will make decisions only when he's ready to do so, and he won't do so in a hurry. This is probably why they tend to avoid trouble and get into troubling situations. Even though their decisions might be praiseworthy, their decision-making process is quite complex and difficult to predict. 
 <br/><br/>
People with this personality type are often described as being slow to make decisions. It can sometimes place them in a troubling situation, as others would rather have a decision made than not have it. But because of their wise decision-making, their choices are almost always praiseworthy. People with this personality type tend to think things through and consider all angles and facts before deciding.
 <br/><br/>
 
Nothing can push a Scorpio woman from her ground. She has complete faith in her choices and won't change them because someone has advised/ asked her to.
<br/><br/>
Ask 3 Personal questions service is an online question-answering service where you can get the best answers from millions of experts. In addition, you can also avail our paid memberships, which will allow you to get answers faster and get the best answers from experts.
<br/><br/>
She is a good person who has a perfect character. She is a sincere person. She is very religious, and she will never lie to you. She is a very caring person.
<br/><br/>
Our company deals with a lot of different issues. Our website and social media pages are for you to find answers to your questions. Our team is always available to please you in any way that you think is necessary.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">You might also be interested to read about</p> <a href="https://www.bodhi.app/zodiac-signs/scorpio/scorpio-man" className="underline text-black italic">Scorpio Man</a>. 
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
